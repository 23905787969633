@media screen and (min-width: 765px) and (max-width: 1000px) {
        .icon-card-about {
                width: 80% !important;
        }
        .fixed-height{
                min-height: 220px !important;
        }
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
        .icon-card-about {
                width: 80% !important;
        }
        .fixed-height{
                min-height: 220px !important;
        }
}

.icon-card-about {
        position: absolute;
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
        top: -45%;
        z-index: 100;
}


@media screen and (max-width: 470px) {
        .icon-card-about {
                width: 45% !important;
        }
}

@media only screen and (max-width: 486px) {

        .center-card-about{
                transform: translateX(0%) !important;
                width: 100% !important;
        }
        .center-card-fixed-height{
                transform: translateX(0%) !important;
                width: 100% !important;
        }
        .fixed-height{
                min-height: fit-content !important;
        }
}

.center-card-about{
        transform: translateX(0%);
        width: 60%;
}

.fixed-height{
        min-height: 200px;
}

.c-level-style{
        border: solid;
        border-radius: 50%;
        transition: all .2s ease-in-out;
        width: 100%;
}

.c-level-style:hover{
        transform: scale(1.1);
}