.blue-bg .card{
        border: none;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
        position: relative;
}

.icon-card {
        position: absolute;
        width: 45%;
        left: 50%;
        transform: translateX(-50%);
        top: -25%;
        z-index: 100;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
        .icon-card {
                width: 75%;
        }
}

@media only screen and (max-width: 486px) {
        .icon-card {
                width: 45%;
        }

        .center-card{
                transform: translateX(0%) !important;
                width: 100% !important;
        }
}

.center-card{
        transform: translateX(35%);
        width: 60%;
}

.pt-0{
        padding-top: 1rem;
}