.section-landing {
  margin-bottom: 70px;
}

.text-color-blue {
  color: #313286;
}

.margin-top {
  padding-top: 100px;
}

.margin-bottom {
  padding-bottom: 100px;
}

.pb-section{
  position: relative;
  background-image: url('../ba-first.png');
  background-size: cover; /* Adjust as needed */
  background-position: center center; /* Adjust as needed */
  background-repeat: no-repeat;
}

.blue-bg{
  position: relative;
  background-image: url('../blue-bg.png');
  background-size: cover; /* Adjust as needed */
  background-position: center center; /* Adjust as needed */
  background-repeat: no-repeat;
}

.mix-bg{
  position: relative;
  background-image: url('../mix.png');
  background-size: cover; /* Adjust as needed */
  background-position: center center; /* Adjust as needed */
  background-repeat: no-repeat;
}

@media screen and (max-width: 1290px) {
  .margin-top {
    padding-top: 40px !important;
  }
  .margin-bottom {
    padding-bottom: 40px !important;
  }
}


/* navbar start */

.navbar-expand-lg .navbar-nav {
  display: flex;
  align-items: center;
}

.navbar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
  /* position: fixed; */
  width: 100%;
  z-index: 100000;
  background-color: white;
}

.playstore-logo {
  margin-left: 20px;
}

.flag {
  width: 40px;
}

.playstore-logo img {
  width: 120px;
  height: auto;
}

hr {
  width: 90%;
  margin: auto;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  animation: fadeIn 0.3s ease-in-out;
  border-radius: 0;
  border: none;
  box-shadow: 1px 1px 5px 1px grey;
  /* border: none; */
}

.item-description {
  font-size: 12px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link {
  color: #000;
}

.zeals-logo {
  width: 150px;
}

.zeals-logo-footer{
  width: 25%;
  padding-top: 10px;
  padding-bottom:10px;
}

/* navbar end */

/* form start */
.blue-color-bg{
  background-color: #edf5fd;
}
/* form end */

/* Footer start */
footer {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  background-color: #fafafa;
  color: #313286;
}

footer a {
  color: #313286;
}

footer a:hover {
  color: #ffad01;
}

.txt-footer a {
  display: flex;
  flex-direction: column;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-decoration: none;
}

.link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}

.footer h4 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 20px;
}

/* .btn-black {
  color: #ffad01;
  background-color: white;
  width: 100%;
  border: solid 1px #313286;
}

.btn-black:hover {
  border: #ffad01 solid 1px;
  color: #313286;
  background-color: #ffad01;
} */
.nopad{
  padding-left: 0px;
  padding-right: 0px;
}
.sosmed-data{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.sosmed-data .sosmed-group{
  display: flex;
  flex-direction: row;
}
.sosmed-data .playstore-logo{
  width: fit-content !important;
}
.sosmed-data .fs-4 {
  border: solid;
  border-radius: 50%;
  margin: 10px;
  padding: 5px;
}

.fs-counter{
  font-size: 70px;
}

@media screen and (max-width: 990px) {
  .item-description {
    display: none;
  }
}
@media screen and (max-width: 1048px) {
  .navbar-expand-lg .navbar-nav {
    display: flex;
    align-items: start;
  }
  .dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center {
    position: relative;
    width: 100%;
  }

  .fs-counter{
    font-size: 48px;
  }

  .btn-landing{
    font-size: 18px !important;
    white-space: nowrap;
  }

  footer .btn-landing{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media screen and (max-width: 498px) {
  .zeals-logo {
    width: 120px;
    height: auto;
  }
  .zeals-logo-footer{
    width: 50%;
  }
  .sub-title{
    font-size: 16px !important;
  }
  .playstore-logo img {
    width: 150px;
    height: auto;
  }
  .sosmed-data{
    padding-top: 25px;
    flex-direction: column-reverse;
    gap:15px;
  }

  .nav-item.dropdown:hover .dropdown-menu{
    box-shadow: none !important;
  }
  
}

/* testimoni */
.custom-card {
  max-width: 95%;
}

/* client */
@media screen and (min-width: 990px) {
  .client-logo{
    padding: 0px 20px 0px 20px;
  } 
}

.client-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auth-box {
  width: 350px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.form-container {
  margin: auto;
  max-width: 500px;
}



.form-group {
  margin: 20px 0;
}

.category-label {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.form-check {
  margin: 5px 0;
}

.form-check-label {
  margin-left: 10px;
}

.form-control.jury-notes {
  height: 100px;
}

.form-check{
  padding-left: 2rem;
}