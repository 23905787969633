.text-top{
        margin-top: 15vh;
}

@media screen and (max-width: 1000px) {
        .text-top{
                margin-top: 0;
        }
}

@media screen and (min-width: 765px) and (max-width: 1000px) {
        .icon-card-academy {
                width: 50% !important;
        }
}

.icon-card-academy {
        position: absolute;
        width: 27%;
        left: 50%;
        transform: translateX(-50%);
        top: -25%;
        z-index: 100;
}


.tiktok-image:hover{
        transform: scale(1.1);
}

@media screen and (max-width: 470px) {
        .icon-card-academy {
                width: 25% !important;
        }

}

.zeals-academy-photos{
        width: 100%;
        height: auto;
}