.whatsapp-float {
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 1000;
        transition: transform 0.3s ease-in-out;
}

.whatsapp-float:hover {
        transform: scale(1.1);
}

.whatsapp-float img {
        width: 60px;
        /* Adjust the size as needed */
        height: auto;
}