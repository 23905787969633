/* .form-section {
        background: linear-gradient(#313286, #2dbcf7, #ffad01);
}

.form-input {
        border: 1px solid #313286;
} */

.background-section {
        position: relative;
        background-image: url('../../form.jpg');
        background-size: cover; /* Adjust as needed */
        background-position: center center; /* Adjust as needed */
        background-repeat: no-repeat;
}

.background-section::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5; /* Adjust the opacity value as needed */
        /* z-index: -1; */
}

.text-header{
        position: relative;
        z-index: 1;
}

.form span {
        color: red;
}

.shadow-box {
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
        transition: 0.3s;
}

.shadow-box:hover {
        box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, .2);
}

.font-awesome .font-awesome-form {
        padding: 5px;
        border: solid;
        margin: 10px;
        border-radius: 50%;
        color: #313286;
}