.bdba-logo {
        width: 90%;
}

.bdba-video {
        height: 400px !important;
}

.accordion-item .accordion-button:not(.collapsed) {
        background-color: #fff;
}

.accordion-item:not(:first-of-type) {
        border-top: 1px solid rgba(0, 0, 0, .125);
}

.accordion-button {
        border-bottom: 1px solid #fff !important;
}

.accordion-button.collapsed {
        border-bottom: 1px solid transparent !important;
}

.accordion-button:focus {
        box-shadow: unset;
}

.accordion-button:not(.collapsed)::after {
        filter: grayscale(1);
}

.accordion-button:not(.collapsed) {
        color: #0d6efd !important;
}

#accordionExample .accordion-button:hover {
        color: #0d6efd;
}