.advertise .card {
        border: none;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
        position: relative;
        height: 270px;
}

.icon-card-advertise {
        position: absolute;
        width: 75%;
        left: 50%;
        transform: translateX(-50%);
        top: -35%;
        z-index: 100;
}

.overlay {
        position: absolute;
        bottom: 0; 
        left: 0;
        right: 0;
        background-color: #313286;
        overflow: hidden;
        width: 100%;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
        height: 0;
        transition: .5s ease;
}

.card:hover .overlay {
        bottom: 0;
        height: 100%;
}

.text {
        color: white;
        font-size: 13px;
        position: absolute;
        overflow: hidden;
        top: 25%;
}

@media screen and (max-width: 390px) {
        .text {
                top: 20%;
        }
}

.icon-card-advertise {
        position: absolute;
        width: 45%;
        left: 50%;
        transform: translateX(-50%);
        top: -25%;
        z-index: 100;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
        .icon-card-advertise {
                width: 75%;
        }
        .overlay {
                overflow-y: scroll;
        }
}

@media only screen and (max-width: 486px) {
        /* .icon-card-advertise {
                width: 45%;
        } */
        .text {
                font-size: 16px;
        }
        .overlay {
                overflow-y: scroll;
        }
        .center-card-advertise{
                transform: translateX(0%) !important;
                width: 100% !important;
        }
}

.center-card-advertise{
        transform: translateX(5%);
        width: 95%;
}
