/* counter */

.contoh {
        position: relative;
        padding-bottom: 10px;
}

.arrow_box {
        position: absolute;
        bottom: -125px;
        height: fit-content;
        width: 100%;
}

.pb-first {
        position: relative;
        background-image: url('../../ba-first.png');
        background-size: cover;
        /* Adjust as needed */
        background-position: center center;
        /* Adjust as needed */
        background-repeat: no-repeat;
        padding-bottom: 70px;
}

.fs-0 {
        font-size: 70px;
}

.counted-row {
        padding-top: 15px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .2);
}

/* client */




/* first section */


.hexagon-logo {
        width: 75%;
}

.typing {
        background: linear-gradient(27deg, #313286, #2dbcf7, #ffad01);
        -webkit-background-clip: text;
        color: transparent;
}

.desc {
        letter-spacing: 4px;
        color: #313286;
        font-weight: 700;
}

.counted {
        letter-spacing: 4px;
}

@media screen and (max-width: 990px) {
        .fs-0 {
                font-size: 40px;
        }

        /* .arrow_box{
                top: -12vh !important;
                height: fit-content;
                width: 100%;
        } */

        .contoh {
                padding-bottom: 0px;
        }
}



@media screen and (max-width: 755px) {
        .fs-0 {
                font-size: 30px;
        }

        .contoh {
                padding-bottom: 30px;
        }
}

.btn-landing {
        background-color: #313286;
        color: white;

}

.btn-landing:hover {
        background-color: #313286;
        color: white;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);

}

/* end of first section */

@media screen and (max-width: 498px) {
        .join-group-button {
                display: flex !important;
                gap: 15px;
                vertical-align: middle;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-bottom: 25px;
        }

        .join-group-button .btn-landing {
                font-size: 14px !important;
                width: 100%;
                padding: 10px 25px !important;
        }

        .section-landing h1 {
                font-size: 24px !important;
        }

        .section-landing {
                padding-bottom: 100px;
        }

        .section-landing .sub-title {
                font-size: 16px !important;
        }

        .arrow_box .fs-counter {
                font-size: 24px !important;
        }

        .arrow_box .desc {
                font-size: 13px !important;
                letter-spacing: 0px;
        }

        .arrow_box .counted-box {
                margin-bottom: 20px;
        }
}

/* talk bubble */
.talk-bubble {
        /* margin: 40px; */
        display: inline-block;
        position: relative;
        width: 100%;
        height: auto;
        background-color: #b1d6f1;
}

.round {
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
}

.tri-right.btm-left:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -20px;
        border: 22px solid;
        border-color: transparent transparent transparent #b1d6f1;
}

.talktext {
        padding: 1em;
        text-align: left;
        line-height: 1.5em;
}

.talktext p {
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
}

.avatar-container {
        width: 100px; /* Adjust the width to your preference */
        height: 100px; /* Adjust the height to your preference */
        overflow: hidden;
        /* border-radius: 50%; */
         /* Adjust the spacing between avatar and name */
}
      
      /* Styling for the avatar image */
.avatar-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
}
      